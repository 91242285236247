import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { padStart } from 'lodash';

import useTimer from '../../hooks/useTimer';

import './Timer.css'

const INTERVAL = 1000

const Timer: React.FC = () => {
  const [ running, setRunning ] = useState(false)

  const [ seconds, setSeconds ] = useState(60)

  const { counter, reset } = useTimer(INTERVAL, !running)

  useLayoutEffect(() => {
    if (!running) return

    reset()
  }, [ running, reset ])

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key !== 'Escape' || !running) return
      e.preventDefault()

      setRunning(false)
      reset()
    }

    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [ reset, running ])

  const timeValues = useMemo(() => {
    const currentSeconds = Math.max(0, seconds - (running ? counter : 0))

    const _minutes = padStart(Math.floor(currentSeconds / 60).toString(), 2, '0')
    const _seconds = padStart((currentSeconds % 60).toString(), 2, '0')

    return [
      _minutes,
      _seconds,
    ]
  }, [ seconds, running, counter ])

  return (
    <div className="timer--container">
      <div className="timer--counter">
        {!running && (
          <input
            type="time"
            inputMode="numeric"
            value={timeValues.join(':')}
            onChange={e => {
              const { value } = e.currentTarget
              const [ minutes = '00', seconds = '00' ] = value.split(':')
              const totalSeconds = Number(minutes || 0) * 60 + Number(seconds || 0)
              
              setSeconds(totalSeconds)
            }}
            className="timer--input"
            autoFocus
          />
        )}

        {running && (
          <div className="timer--display">
            {timeValues.join(':')}
          </div>
        )}
      </div>

      <div className="timer--clock">
        <svg
          className="timer--clock-pointer"
          viewBox="0 0 20 20"
          style={{ '--interval': running ? INTERVAL : 100 } as any}
        >
          <circle r="5" cx="10" cy="10" fill="transparent"
            stroke="#fff"
            strokeWidth="10"
            strokeDasharray={`calc(${(Math.min((running ? counter + 1 : 0) * 100 / seconds, 100)).toFixed(2)} * 31.42 / 100) 31.42`}
            transform="rotate(-90deg) translate(-20)"
          />
        </svg>
      </div>

      <div className="timer--buttons">
        <button
          className="timer--start"
          onClick={() => setRunning(running => !running)}
          style={{ opacity: running ? 0 : 1 }}
        >
          Iniciar
        </button>
        {running && (
          <div className="timer--warning">
            A reunião logo irá começar
          </div>
        )}
      </div>
    </div>
  )
}

export default Timer;