import { useCallback, useEffect, useState } from "react";

export default function useTimer(interval: number, disabled: boolean = false) {
  const [ counter, setCounter ] = useState(0)

  useEffect(() => {
    if (disabled) return

    const timer = setInterval(() => {
      setCounter(counter => counter + 1)
    }, interval)
    return () => {
      clearInterval(timer)
    }
  }, [ interval, disabled ])

  const reset = useCallback(() => {
      setCounter(0)
  }, [ setCounter ])

  return {
    counter,
    reset,
  }
}
